import 'nprogress/nprogress.css';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { NotificationsProvider } from 'reapop';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import App from './App';
import './styles/index.css';
import reportWebVitals from './reportWebVitals';
import { store } from './store';
import './store/axios'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <HelmetProvider>
    <SidebarProvider>
      <Provider store={store}>
        <Router>
          <NotificationsProvider>
            <App />
          </NotificationsProvider>
        </Router>
      </Provider>
    </SidebarProvider>
  </HelmetProvider>
);

reportWebVitals();
