import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import axios from '../axios';

export const addCountry = createAsyncThunk(
  'config/addCountry',
  async (country_name: string, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.post('/admin/countries', { country_name });
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getAllCountries = createAsyncThunk(
  'config/getAllCountries',
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.get('/countries', {
        headers: { "Content-type": "application/json" }
      });
      return fulfillWithValue(data.countries);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getSystemConfig = createAsyncThunk(
  'config/getSystemConfig',
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.get('/admin/config');
      return fulfillWithValue(data.configurations);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const updateDocument = createAsyncThunk(
  'config/updateDocument',
  async (newDocs: string[], { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.put(`/admin/config/docs`, { docs: newDocs });
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const updateBlogCategories = createAsyncThunk(
  'config/updateBlogCategories',
  async (categories: string[], { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.put(`/admin/config/blog-categories`, { categories });
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const addKYCSectionTitle = createAsyncThunk(
  'config/addKYCSectionTitle',
  async (title: string, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.post(`/admin/config/kyc-section-titles`, { title });
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const updateKYCSectionTitle = createAsyncThunk(
  'config/updateKYCSectionTitle',
  async ({ title, id }: { title: string, id: number }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.put(`/admin/config/kyc-section-titles/${id}`, { title });
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const deleteKYCSectionTitle = createAsyncThunk(
  'config/deleteKYCSectionTitle',
  async (id: number, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.delete(`/admin/config/kyc-section-titles/${id}`);
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const deleteCountry = createAsyncThunk(
  'config/deleteCountry',
  async (id: number, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.delete(`/admin/countries/${id}`);
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getJobTags = createAsyncThunk(
  'config/getJobTags',
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.get('/tags');
      return fulfillWithValue(data.tags);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getKYCSectionTitles = createAsyncThunk(
  'config/getKYCSectionTitles',
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.get('/admin/config/kyc-section-titles');
      return fulfillWithValue(data.titles);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getVisaTypes = createAsyncThunk(
  'config/getVisaTypes',
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.get('/visa-types');
      return fulfillWithValue(data.types);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const deleteJobTag = createAsyncThunk(
  'config/deleteJobTag',
  async (id: number, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.delete(`/admin/tags/${id}`);
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const addJobTag = createAsyncThunk(
  'config/addJobTag',
  async (tag_name: string, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.post('/admin/tag', { tag_name });
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const addService = createAsyncThunk(
  'config/addService',
  async ({ service_name, service_fee, id }: { service_name: string, service_fee: number, id?: number }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.post('/admin/agency-service', { service_name, service_fee, id }, {
        headers: { "Content-type": "application/json" }
      });
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const updateServiceActiveness = createAsyncThunk(
  'config/updateServiceActiveness',
  async ({ is_active, id }: { is_active: boolean, id: number }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.patch(`/admin/agency-service/${id}`, { is_active }, {
        headers: { "Content-type": "application/json" }
      });
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const agencyOfferedServices = createAsyncThunk(
  'config/agencyOfferedServices',
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.get('/offered-services');
      return fulfillWithValue(data.services);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const deleteOfferedServices = createAsyncThunk(
  'config/deleteOfferedServices',
  async (id: number, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.delete(`/admin/offered-services/${id}`);
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);
