import { createSlice } from '@reduxjs/toolkit';
import { DashboardState } from 'src/models/store';
import { asyncIsPending, asyncIsRejected } from 'src/store/asyncConfig';
import { getStats } from './dashboardServices';

const initialState: DashboardState = {
  message: '',
  status: null,
  total_agents: 0,
  total_clients: 0,
  total_jobs: 0,
  requests: [],
  income: [],
  total_income: 0
}

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder.addCase(getStats.pending, asyncIsPending)
    builder.addCase(getStats.rejected, asyncIsRejected)
    builder.addCase(getStats.fulfilled, (state, action) => {
      console.log('FULFILLED:: ', action.payload);
      state.status = null;
      state.total_agents = action.payload.total_agents;
      state.total_clients = action.payload.total_clients;
      state.total_jobs = action.payload.total_jobs;
      state.requests = action.payload.requests;
      state.income = action.payload.income;
      state.total_income = action.payload.total_income;
    })
  }
});

export default usersSlice.reducer;