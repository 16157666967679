import { createSlice } from '@reduxjs/toolkit';
import { BlogPostState } from 'src/models/store';
import { asyncIsFulfilled, asyncIsPending, asyncIsRejected } from 'src/store/asyncConfig';
import { deletePostById, getPosts, publishPost, savePostAsDraft } from './blogServices';

const initialState: BlogPostState = {
  message: '',
  status: null,
  posts: [],
}

export const blogSlice = createSlice({
  name: 'blog',
  initialState,
  reducers: {
    clearBlogState: (state) => {
      state.status = null;
      state.message = ''
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getPosts.pending, asyncIsPending)
    builder.addCase(getPosts.rejected, asyncIsRejected)
    builder.addCase(getPosts.fulfilled, (state, action) => {
      console.log('FULFILLED:: ', action.payload);
      state.status = null;
      state.posts = action.payload.posts;
    })
    builder.addCase(publishPost.pending, asyncIsPending)
    builder.addCase(publishPost.rejected, asyncIsRejected)
    builder.addCase(publishPost.fulfilled, asyncIsFulfilled)
    builder.addCase(savePostAsDraft.pending, asyncIsPending)
    builder.addCase(savePostAsDraft.rejected, asyncIsRejected)
    builder.addCase(savePostAsDraft.fulfilled, asyncIsFulfilled)
    builder.addCase(deletePostById.pending, asyncIsPending)
    builder.addCase(deletePostById.rejected, asyncIsRejected)
    builder.addCase(deletePostById.fulfilled, asyncIsFulfilled)
  }
});

export const { clearBlogState } = blogSlice.actions;
export default blogSlice.reducer;