import { LoadingButton } from '@mui/lab';
import { Box, CircularProgress, Grid, Link, Paper, TextField, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { notify } from 'reapop';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { AdminCredentials } from 'src/models/admin';
import { AsyncState } from 'src/models/store';
import { adminPasswordReset, verifyResetToken } from 'src/store/auth/authService';
import { clearAuthState } from 'src/store/auth/authSlice';

const ResetPassword: React.FC = () => {
  const [isResetTokenValid, setIsResetTokenValid] = useState<AsyncState>('pending');
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');
  const dispatch = useAppDispatch();
  const authState = useAppSelector((state) => state.auth);
  const [credentials, setCredentials] = useState<AdminCredentials>({
    email_address: '',
    password: '',
    confirm_password: ''
  });

  useEffect(() => {
    if (authState.stage !== 'change-password') {
      setIsResetTokenValid(authState.status);
    }
    if (authState.stage === 'change-password' && authState.status === 'fulfilled') {
      dispatch(notify(authState.message, 'success'))
      setTimeout(() => {
        navigate('/login');
        dispatch(clearAuthState())
      }, 1000);
    }
    if (authState.status === 'rejected') {
      dispatch(notify(authState.message, 'error'))
      dispatch(clearAuthState())
      navigate('/login');
    }
    // eslint-disable-next-line
  }, [authState]);

  useEffect(() => {
    dispatch(verifyResetToken(token ?? ''))
    // eslint-disable-next-line
  }, [])

  const handleFormSubmit = (e: FormEvent) => {
    e.preventDefault();
    const { password, confirm_password } = credentials;
    if (password !== confirm_password) {
      dispatch(notify('Both passwords should be the same', 'error'))
    } else {
      dispatch(adminPasswordReset({ password, token: token ?? '' }));
    }
  }

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  }

  return (
    <>
      {isResetTokenValid === 'pending' ? <CircularProgress /> :
        isResetTokenValid === 'fulfilled' ? <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <motion.div
            key={'loginForm'}
            initial={{ y: -100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -100, opacity: 0 }}
            transition={{ duration: 0.8 }}
          >
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <br />
              <br />
              <br />
              <Typography component="h1" variant="h1">
                Reset Password
              </Typography>
              <Typography component={'h6'}>
                Please enter your new password
              </Typography>
              <Box component="form" noValidate onSubmit={handleFormSubmit} sx={{ mt: 2 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  value={credentials.password}
                  onChange={handleInputChange}
                  name="password"
                  label="Password"
                  type="password"
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  value={credentials.confirm_password}
                  onChange={handleInputChange}
                  name="confirm_password"
                  label="Confirm Password"
                  type="password"
                />
                <LoadingButton
                  type="submit"
                  fullWidth
                  loading={authState.status === 'pending'}
                  loadingPosition="start"
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Submit
                </LoadingButton>
              </Box>
              <Typography align='center'>
                <Link href="/login" fontWeight={600} underline="none" variant="body2">
                  {"Remember your password now? Let's login"}
                </Link>
              </Typography>
            </Box>
          </motion.div>
        </Grid> : <></>
      }
    </>
  )
}

export default ResetPassword