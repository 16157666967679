import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { BlogPost } from "src/models/blog";
import axios from '../../axios';

export const getPosts = createAsyncThunk(
  'blog/getPosts',
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/admin/posts`);
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const deletePostById = createAsyncThunk(
  'blog/deletePostById',
  async (id: number, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.delete(`/admin/posts/${id}`);
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const savePostAsDraft = createAsyncThunk(
  'blog/savePostAsDraft',
  async (post: BlogPost, { fulfillWithValue, rejectWithValue }) => {
    try {
      const formData = new FormData();
      Object.keys(post).forEach((key) => {
        formData.append(key, post[key as keyof BlogPost] as any);
      })
      const { data } = await axios.post(`/admin/posts/draft`, formData, {
        headers: { "Content-type": "multipart/form-data" }
      });
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const publishPost = createAsyncThunk(
  'blog/publishPost',
  async (post: BlogPost, { fulfillWithValue, rejectWithValue }) => {
    try {
      const formData = new FormData();
      Object.keys(post).forEach((key) => {
        formData.append(key, post[key as keyof BlogPost] as any);
      })
      const { data } = await axios.post(`/admin/posts/publish`, formData, {
        headers: { "Content-type": "multipart/form-data" }
      });
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);