import { configureStore } from '@reduxjs/toolkit'
import authSlice from './auth/authSlice'
import applicationSlice from './applications/applicationSlice'
import notificationSlice from './notifications/notificationSlice'
import configSlice from './configurations/configSlice'
import usersSlice from './users/usersSlice'
import dashboardSlice from './general/dashboard/dashboardSlice'
import blogSlice from './general/blog/blogSlice'
import { reducer as notificationsReducer } from 'reapop'

export const store = configureStore({
  reducer: {
    notifications: notificationsReducer(),
    auth: authSlice,
    users: usersSlice,
    dashboard: dashboardSlice,
    config: configSlice,
    blog: blogSlice,
    applications: applicationSlice,
    app_notification: notificationSlice
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch