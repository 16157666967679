import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import axios from '../../axios';

export const getStats = createAsyncThunk(
  'dashboard/getStats',
  async (year: number, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/admin/stats/${year}`);
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);